import React from 'react';
import { Grid } from 'semantic-ui-react';
import './App.css';
import Dropzone from './Components/Dropzone';

function App() {
  return (
    <div style={{ overflowX: "hidden"}}>
      <Grid centered verticalAlign="middle" columns="1" doubling style={{ height: '100vh', backgroundColor: '#5985a0' }}>
        <Grid.Row>
          <Grid.Column width={10}>
            <Dropzone />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default App;
