import React, { useState } from 'react';
import { Segment, Button, Dimmer, Loader, Label } from 'semantic-ui-react';
import { useDropzone } from 'react-dropzone';
import ReactJson from 'react-json-view'
// import jsonRaw from 'res.json';

interface UploadState {
    isUploading: boolean;
    uploaded: boolean;
    result?: any;
    error?: any;
}

const Dropzone = () => {
    const { getRootProps, getInputProps, acceptedFiles, open } = useDropzone({
        accept: {
            'application/pdf': ['.pdf']
        },
        multiple: false,
        maxFiles: 1,
        onDrop: (acceptedFiles, fileRejections, event) => {
            setFile(acceptedFiles[0]);
            console.log(acceptedFiles);
        }
    });

    const files = acceptedFiles.map(file => (
        <li key={file.name}>
          {file.name} - {Number(file.size / 1024 / 1024).toFixed(2)} Megabytes
        </li>
      ));

      const [file, setFile] = useState<File>();
      const [upload, setUpload] = useState<UploadState>({ isUploading: false, uploaded: false, result: undefined, error: undefined });

      const handleUpload = () => {
        if (!file) return;

        setUpload({ isUploading: true, uploaded: false, result: undefined });

        const formData = new FormData()
        formData.append("file", file);

        fetch("https://ocr.tuerkasten.de/api/extract-text", {
            method: "POST",
            body: formData,
        }).then(async res => {
            if (res.ok) {
                setUpload({ isUploading: false, uploaded: true, result: await res.json() });
            }
        }).catch(error => setUpload({ ...upload, error }));
      }

    return (
        <>
            {!!!upload.uploaded && !!!upload.result && <Segment raised padded="very" className="container">
                <div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    <p>Datei Upload</p>
                    <p>Nur PDF Files werden akzeptiert</p>
                    <Button size='tiny' content="Wähle Datei" onClick={open} />
                </div>
            </Segment>
            }
            {!!!upload.uploaded && !!!upload.result && !!files.length && 
                <Segment raised padded="very">
                    <div>
                        <h3>Datei</h3>
                        <ul>{files}</ul>
                    </div>
                    <div>
                        <Button color="facebook" content="Starte Texterkennung" disabled={!!!file?.size} onClick={handleUpload} />
                    </div>
                </Segment>
            }
            {!!upload.uploaded && !!upload.result &&
                <Segment padded="very" raised>
                    <ReactJson src={upload.result.ocr_detection} indentWidth={3} name={null} collapsed={2} theme={"eighties"} iconStyle='triangle' style={{ padding: "1.5rem", borderRadius: ".28571429rem" }} />
                </Segment>
            }

            {/* <Segment padded="very" raised>
                <ReactJson src={jsonRaw.ocr_detection} indentWidth={3} name={null} collapsed={2} theme={"eighties"} iconStyle='triangle' style={{ padding: "1.5rem", borderRadius: ".28571429rem" }} />
            </Segment> */}
            <Dimmer active={upload.isUploading} page inverted>
                <Loader size="large">
                    <Label circular basic size="large" color="grey">
                        {upload.isUploading && "Konvertiere Text 🏋️‍♀️"}
                    </Label>
                </Loader>
            </Dimmer>
        </>
    );
}

export default Dropzone;